import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
  EnableDisableUser,
  ResetEnableDisableUserState,
} from './user-enable-disbale.action';
import { CommonResponseModel, UserEnableDisableService} from '@shared';

const stateDefaults: CommonResponseModel = {
  status_code: 0,
  message: '',
};

@State<CommonResponseModel>({
  defaults: stateDefaults,
  name: 'userEnableDisable',
})
@Injectable()
export class UserEnableDisableState {
  constructor(private userEnableDisableService: UserEnableDisableService) {}

  @Action(EnableDisableUser)
  public enableDisableUser(
    { patchState }: StateContext<CommonResponseModel>,
    {action, userId, }: EnableDisableUser
  ): Observable<CommonResponseModel> {
    return this.userEnableDisableService
      .enableOrDisableUser(action, userId)
      .pipe(
        tap((results) => {
          patchState({ ...results });
        })
      );
  }

  @Action(ResetEnableDisableUserState)
  public resetEnableDisableUserState({ patchState }: StateContext<CommonResponseModel>): void {
    patchState({ ...stateDefaults });
  }

  @Selector()
  public static returnEnableDisableResponse(
    state: CommonResponseModel
  ): CommonResponseModel {
    return state;
  }
}
