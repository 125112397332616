/** NGXS recommends we keep related actions in a single file hence below Linting exception is added.
/*  See : https://www.ngxs.io/concepts/actions#group-your-actions */
/* eslint-disable max-classes-per-file */

export class EnableDisableUser {
  public static type = '[EnableDisableUser] Enable or disable user ';
  constructor(
    public action : string,
    public userId: string,
  ) {}
}

export class ResetEnableDisableUserState {
  public static type = '[EnableDisableUser] Reset response state';
}
