import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CommonResponseModel } from '@shared/interfaces';
import { HeaderParams } from '@core/interfaces';
import { UserProjectRoleEnum } from '@core';

@Injectable({
  providedIn: 'root',
})
export class UserEnableDisableService {
  constructor(private http: HttpClient) { }

  public enableOrDisableUser(
    action: string,
    userId: string,
  ): Observable<CommonResponseModel> {
    const headers = new HttpHeaders().append(
      HeaderParams.ROLENAME,
      UserProjectRoleEnum.PLATFORM_ADMIN
    );
    return this.http.put<CommonResponseModel>(
      `/user/disable`,
      {
          action,
          userId
      },
      { headers }
    );
  }
}